import React, { useState, useEffect, useReducer, useContext } from "react";
import { BrowserRouter } from "react-router-dom";

// material-ui
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";

import ThemeRoutes from "./routes";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import AuthService from "./services/AuthService";
import { useAccountService } from "./views/settings/account/useAccountService";
import { useEntityService } from "./views/settings/entity/useEntityService";

export const fp = (async () => {
  const fp = await FingerprintJS.load();
  return await fp.get();
})();

export const AuthContext = React.createContext();
export const EntityContext = React.createContext();

const themeOptions = {
  direction: "ltr",
  // palette: themePalette(themeOption),
  mixins: {
    toolbar: {
      minHeight: "48px",
      padding: "16px",
      "@media (min-width: 600px)": {
        minHeight: "48px",
      },
    },
  },
  // typography: themeTypography(themeOption)
};

const theme = createTheme(themeOptions);

const initialState = {
  token: null,
  authUser: null,
  isAuthenticated: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        authUser: action.payload.authUser,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        authUser: null,
        token: null,
      };
    case "UPDATE":
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    default:
      return state;
  }
};

function App() {
  const [authState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    AuthService.checkAuth().then((res) => {
      if (res.status === "success") {
        dispatch({
          type: "LOGIN",
          payload: {
            authUser: res.data.auth,
            token: res.data.token,
          },
        });
      } else {
        dispatch({ type: "LOGOUT" });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        dispatch,
      }}
    >
      <Entity>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <ThemeRoutes />
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </Entity>
    </AuthContext.Provider>
  );
}

const Entity = (props) => {
  const { authState } = useContext(AuthContext);

  const { myAccount, findPermissionByRoles } = useAccountService();

  const [loading, setLoading] = useState(true);
  const [accountEntity, setAccountEntity] = useState();
  const [activeEntity, setActiveEntity] = useState();
  const [entityList, setEntityList] = useState([]);
  const [authPermissions, setAuthPermissions] = useState([]);

  useEffect(() => {
    if (authState) {
      myAccount().then((res) => {
        if (res.status === "success") {
          setAccountEntity(res.data);

          if (res.data["Entity/_Manages"] && res.data["Entity/_Manages"].length > 0) {
            setEntityList(res.data["Entity/_Manages"]);
            setActiveEntity(res.data["Entity/_Manages"][0]);
          }
        } else {
          setActiveEntity(null);
        }
      });
      // findEntityByToken().then((res) => {
      //   if (res.status === "success") {
      //     setAccountEntity(res.data);
      //     setActiveEntity(res.data["Entity/ManagedBy"][0]);
      //     setEntityList(res.data["Entity/ManagedBy"]);
      //   } else {
      //     setAccountEntity(null);
      //     setActiveEntity(null);
      //   }
      // });
    }
  }, [authState]);

  useEffect(() => {
    if (activeEntity && activeEntity._id) {
      let roles = accountEntity["Entity/Roles"].map((role) => JSON.parse(role));

      for (const role of roles) {
        if (role["EntityId"] === activeEntity._id) {
          role["EntityTypes"] = activeEntity["Entity/Types"];
        }
      }

      findPermissionByRoles(roles).then((res) => {
        if (res.status === "success") {
          setAuthPermissions(res.data);
        }
        setLoading(false);
      });
    }
    // if (activeEntity && activeEntity._id) {
    //   findEntityPermission({
    //     entityId: activeEntity._id,
    //     roles: accountEntity["Entity/Roles"]
    //       .filter((item) => JSON.parse(item)["EntityId"] == activeEntity._id)
    //       .map((item) => JSON.parse(item)["Role"]),
    //   }).then((res) => {
    //     if (res.status === "success") {
    //       setAuthPermissions(res.data);
    //     }
    //     setLoading(false);
    //   });
    // }
  }, [activeEntity, accountEntity]);

  const can = (route) => {
    if (authPermissions.includes(route)) {
      return true;
    }
    return false;
  };

  return (
    <EntityContext.Provider value={{ loading, can, activeEntity, setActiveEntity, entityList, authPermissions }}>
      {props.children}
    </EntityContext.Provider>
  );
};

export default App;
