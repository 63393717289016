import { useCallback, useContext } from "react";
import { httpFetch } from "../../../services/AdminApiService";
import { AuthContext, fp } from "../../../App";

const useEntityService = () => {
  const { authState } = useContext(AuthContext);

  const findAll = useCallback(
    async (query = {}) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities?types=" + query.types, config);
    },
    [authState.token]
  );

  const findAllByTag = useCallback(
    async (query = {}) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities/tags", config);
    },
    [authState.token]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities", config);
    },
    [authState.token]
  );

  const findOne = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities/" + id, config);
    },
    [authState.token]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id, config);
    },
    [authState.token]
  );

  const destroy = useCallback(
    async (id) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities/" + id, config);
    },
    [authState.token]
  );

  // const findPermission = useCallback(
  //   async (id) => {
  //     const config = {
  //       method: "get",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //     };

  //     return await httpFetch("/entities/" + id + "/findPermission", config);
  //   },
  //   [authState.token]
  // );

  return {
    findAll,
    findAllByTag,
    create,
    findOne,
    update,
    destroy,
  };
};
export { useEntityService };
