import { lazy, useContext } from "react";
import { useRoutes } from "react-router-dom";
import config from "../config";
import AdminRoutes from "./AdminRoutes";
import WebRoutes from "./WebRoutes";
import Loadable from "../components/loadable/Loadable";
import { EntityContext } from "../App";
const DashboardPagePrint = Loadable(lazy(() => import("../views/dashboard/DashboardPagePrint")));

export default function ThemeRoutes() {
  const { can } = useContext(EntityContext);

  return useRoutes(
    [
      AdminRoutes(can),
      WebRoutes(),
      {
        path: "/dashboardPagePrint",
        element: <DashboardPagePrint />,
      },
    ],
    config.basename
  );
}
