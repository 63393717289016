const sinisana = {
  id: "sinisana",
  title: "Sinisana",
  type: "group",
  permission: "Sinisana",
  children: [
    {
      id: "security",
      title: "Security",
      type: "item",
      url: "/admin/sinisana/security",
    },
    {
      id: "organization",
      title: "Organization",
      type: "item",
      url: "/admin/sinisana/organizations",
    },
    {
      id: "entity",
      title: "Entity",
      type: "item",
      url: "/admin/sinisana/entities",
    },
    {
      id: "user",
      title: "User",
      type: "item",
      url: "/admin/sinisana/users",
    },
  ],
};

export default sinisana;
