// assets
import ArticleIcon from "@mui/icons-material/Article";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const manage = {
  id: "manage",
  title: "Manage",
  type: "group",
  permission: "Dispute",
  children: [
    {
      id: "dispute",
      title: "Dispute",
      type: "item",
      url: "/admin/manage/dispute",
      icon: FeedbackIcon,
    },
    {
      id: "documentCheckResponse",
      title: "Document Check",
      type: "item",
      url: "/admin/manage/documentCheckResponses",
      icon: ArticleIcon,
    },
    {
      id: "safetyCheckResponse",
      title: "Safety Check",
      type: "item",
      url: "/admin/manage/safetyCheckResponses",
      icon: SafetyCheckIcon,
    },
  ],
};

export default manage;
