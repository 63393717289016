import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import SearchIcon from "@mui/icons-material/Search";

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  permission: "Dashboard",
  children: [
    {
      id: "dashboardPage",
      title: "Dashboard",
      type: "item",
      url: "/admin/dashboard/dashboardPage",
      icon: DesktopWindowsOutlinedIcon,
    },
    {
      id: "search",
      title: "Search",
      type: "item",
      url: "/admin/dashboard/search",
      icon: SearchIcon,
    },
  ],
};

export default dashboard;
