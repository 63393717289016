import { lazy } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// project imports
import AdminLayout from "../components/layouts/AdminLayout";
import Loadable from "../components/loadable/Loadable";
import UserDetail from "../modules/sinisana/user/UserDetail";

const Profile = Loadable(lazy(() => import("../views/profile/profile")));

// Dashboard
const DashboardPage = Loadable(lazy(() => import("../views/dashboard/DashboardPage")));
const Search = Loadable(lazy(() => import("../modules/dashboard/Search")));

// Manage routing
const Dispute = Loadable(lazy(() => import("../views/manage/Dispute")));
const DisputeDetail = Loadable(lazy(() => import("../views/manage/DisputeDetail")));
const DocumentCheckResponse = Loadable(lazy(() => import("../modules/documentCheck/DocumentCheckResponse")));
const SafetyCheckResponse = Loadable(lazy(() => import("../modules/safetyCheck/SafetyCheckResponse")));

// transaction routing
const PaymentRecord = Loadable(lazy(() => import("../views/transactions/PaymentRecord")));

// settings routing
const ProductionWorker = Loadable(lazy(() => import("../views/settings/worker/ProductionWorker")));
const ProductionWorkerDetail = Loadable(lazy(() => import("../views/settings/worker/ProductionWorkerDetail")));
const WorkerDetail = Loadable(lazy(() => import("../modules/worker/WorkerDetail")));
const Survey = Loadable(lazy(() => import("../views/settings/survey/Survey")));
const SurveyDetail = Loadable(lazy(() => import("../views/settings/survey/SurveyDetail")));
const WorkerSafetyCheck = Loadable(lazy(() => import("../views/settings/safetyCheck/SafetyCheck")));
const WorkerSafetyCheckDetail = Loadable(lazy(() => import("../views/settings/safetyCheck/SafetyCheckDetail")));
// const WorkerDocumentCheck = Loadable(lazy(() => import("../views/settings/documentCheck/DocumentCheck")));
// const WorkerDocumentCheckDetail = Loadable(lazy(() => import("../views/settings/documentCheck/DocumentCheckDetail")));
const VerificationConfiguration = Loadable(
  lazy(() => import("../views/settings/verificationConfiguration/VerificationConfiguration"))
);

// account routing
const AccountSetting = Loadable(lazy(() => import("../views/account/AccountSetting")));

// Transactions
const PaymentBatch = Loadable(lazy(() => import("../views/transactions/PaymentBatch")));
const PaymentBatchDetail = Loadable(lazy(() => import("../views/transactions/PaymentBatchDetail")));

// Settings
// const Option = Loadable(lazy(() => import("../views/settings/option/Option")));
// const LitsaraProductOptionDetail = Loadable(lazy(() => import("../views/settings/option/LitsaraProductOptionDetail")));

// Sinisana
// const Account = Loadable(lazy(() => import("../views/settings/account/Account")));
// const AccountDetail = Loadable(lazy(() => import("../views/settings/account/AccountDetail")));

// const Entity = Loadable(lazy(() => import("../views/settings/entity/Entity")));
const Organization = Loadable(lazy(() => import("../views/sinisana/organization/Organization")));
const OrganizationDetail = Loadable(lazy(() => import("../views/sinisana/organization/OrganizationDetail")));
const Security = Loadable(lazy(() => import("../views/sinisana/security/Security")));
const SecurityDetail = Loadable(lazy(() => import("../views/sinisana/security/SecurityDetail")));
const EntityPgAdmin = Loadable(lazy(() => import("../views/settings/entity/Entity"))); // To be removed
const Entity = Loadable(lazy(() => import("../modules/sinisana/entity/Entity")));
const EntityDetail = Loadable(lazy(() => import("../modules/sinisana/entity/EntityDetail")));
const User = Loadable(lazy(() => import("../modules/sinisana/user/User")));

const PermissionDenied = () => {
  return (
    <Grid container padding={{ xs: 0, md: 2 }} marginTop={{ md: "24px" }} spacing={2}>
      <Grid item xs={12}>
        <Box>
          <h1>Permission Denied</h1>
          <p>You don't have permission to access this page. Please contact your administrator.</p>
        </Box>
      </Grid>
    </Grid>
  );
};

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = (can) => {
  return {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "dashboard/dashboardPage",
        element: can("DashboardPage") ? <DashboardPage /> : <PermissionDenied />,
      },
      {
        path: "dashboard/search",
        element: can("DashboardPage") ? <Search /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/dispute",
        element: can("Dispute") ? <Dispute /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/disputeDetail/:id",
        element: can("Dispute") ? <DisputeDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/documentCheckResponses",
        element: can("Dispute") ? <DocumentCheckResponse /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/safetyCheckResponses",
        element: can("Dispute") ? <SafetyCheckResponse /> : <PermissionDenied />,
      },
      {
        path: "/admin/transactions/paymentRecord",
        element: can("PaymentRecord") ? <PaymentRecord /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/productionWorkers",
        element: can("Settings") ? <ProductionWorker /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/productionWorkers/:id",
        element: can("Settings") ? <ProductionWorkerDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/workers/:id",
        element: can("Settings") ? <WorkerDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/surveys",
        element: can("Settings") ? <Survey /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/surveys/:id",
        element: can("Settings") ? <SurveyDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/safetyChecks",
        element: can("Settings") ? <WorkerSafetyCheck /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/safetyChecks/:id",
        element: can("Settings") ? <WorkerSafetyCheckDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/settings/verificationConfiguration",
        element: can("Settings") ? <VerificationConfiguration /> : <PermissionDenied />,
      },
      {
        path: "/admin/account/settings",
        element: can("AccountSetting") ? <AccountSetting /> : <PermissionDenied />,
      },
      {
        path: "transactions/paymentBatch",
        element: can("Payment") ? <PaymentBatch /> : <PermissionDenied />,
      },
      {
        path: "transactions/paymentBatch/:id",
        element: can("Payment") ? <PaymentBatchDetail /> : <PermissionDenied />,
      },
      {
        path: "sinisana/security",
        element: can("Sinisana") ? <Security /> : <PermissionDenied />,
      },
      {
        path: "sinisana/security/:id",
        element: can("Sinisana") ? <SecurityDetail /> : <PermissionDenied />,
      },
      // {
      //   path: "sinisana/entity",
      //   element: can("Sinisana") ? <Entity /> : <PermissionDenied />,
      // },
      {
        path: "sinisana/organizations",
        element: can("Sinisana") ? <Organization /> : <PermissionDenied />,
      },
      {
        path: "sinisana/organizations/:id",
        element: can("Sinisana") ? <OrganizationDetail /> : <PermissionDenied />,
      },
      {
        path: "sinisana/entity",
        element: can("Sinisana") ? <EntityPgAdmin /> : <PermissionDenied />,
      },
      {
        path: "sinisana/entities",
        element: can("Sinisana") ? <Entity /> : <PermissionDenied />,
      },
      {
        path: "sinisana/entities/:id",
        element: can("Sinisana") ? <EntityDetail /> : <PermissionDenied />,
      },
      {
        path: "sinisana/users",
        element: can("Sinisana") ? <User /> : <PermissionDenied />,
      },
      {
        path: "sinisana/users/:id",
        element: can("Sinisana") ? <UserDetail /> : <PermissionDenied />,
      },
    ],
  };
};

export default AdminRoutes;
