import { lazy } from "react";
import Loadable from "../components/loadable/Loadable";

const Login = Loadable(lazy(() => import("../views/login/Login")));
const Zkp = Loadable(lazy(() => import("../modules/zkp/zkp")));

// ==============================|| MAIN ROUTING ||============================== //
const WebRoutes = () => {
  return {
    path: "/",
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "zkp",
        element: <Zkp />,
      },
    ],
  };
};

export default WebRoutes;
