// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import { useContext } from "react";
import { EntityContext } from "../../../App";
import menuItems from "../../../menu";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { can } = useContext(EntityContext);

  const navItems = menuItems.items
    .filter((item) => {
      if (!item.permission) return true;
      return can(item.permission);
    })
    .map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return <>{navItems}</>;
};

export default MenuList;
