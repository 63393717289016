import dashboard from "./dashboard";
import manage from "./manage";
import transaction from "./transaction";
import settings from "./settings";
import sinisana from "./sinisana";

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  items: [dashboard, manage, transaction, settings, sinisana],
};

export default menuItems;
