// assets
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PollIcon from "@mui/icons-material/Poll";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
import ArticleIcon from "@mui/icons-material/Article";
import SettingsIcon from "@mui/icons-material/Settings";

const settings = {
  id: "settings",
  title: "Settings",
  type: "group",
  permission: "Settings",
  children: [
    {
      id: "productionWorkers",
      title: "Workers",
      type: "item",
      url: "/admin/settings/productionWorkers",
      icon: GroupsOutlinedIcon,
    },
    {
      id: "surveys",
      title: "Surveys",
      type: "item",
      url: "/admin/settings/surveys",
      icon: PollIcon,
    },
    // {
    //   id: "safetyChecks",
    //   title: "Employee Safety Checks",
    //   type: "item",
    //   url: "/admin/settings/safetyChecks",
    //   icon: SafetyCheckIcon,
    // },
    // {
    //   id: "documentChecks",
    //   title: "Document Checks",
    //   type: "item",
    //   url: "/admin/settings/documentChecks",
    //   icon: ArticleIcon,
    // },
    // {
    //   id: "verificationConfiguration",
    //   title: "Verification Checks",
    //   type: "item",
    //   url: "/admin/settings/verificationConfiguration",
    //   icon: SettingsIcon,
    // },
  ],
};

export default settings;
